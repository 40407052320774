import { datadogRum } from '@datadog/browser-rum'

const DEVELOPMENT = 'dev-local'

const getAppEnvFromUrl = () => {
    if (typeof window != 'undefined') {
        const { host } = window.location
        if (host.includes('dev')) {
            return 'dev'
        }
        if (['stg', 'staging'].some((en) => host.includes(en))) {
            return 'stg'
        }
        if (host === 'dochub.enpal.de' || host.includes('prod')) {
            return 'prd'
        }
        if (host.includes('localhost')) {
            return DEVELOPMENT
        }
    } else return globalThis.process.env.APP_ENV
}

if (getAppEnvFromUrl() !== DEVELOPMENT) {
    datadogRum.init({
        applicationId: '10ae5b61-650c-44f3-8fd5-5b89fdb7eb60',
        clientToken: 'puba7a83daafc21a9d960bee1fa59c1844a',
        site: 'datadoghq.eu',
        service: 'doctemplate-frontend',
        env: getAppEnvFromUrl(),
        version: globalThis.process.env.appVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
            'http://dochub.enpal.de',
            'https://staging.dochub.enpal.de',
            /https:\/\/doctemplate-ui-(stg|dev|prod)-gwc-app-001\.azurewebsites\.net/,
        ],
    })
}
